/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

@import "~leaflet/dist/leaflet.css";
@import "~leaflet-routing-machine/dist/leaflet-routing-machine.css";

@import "~@fortawesome/fontawesome-free/css/all.css";

.leaflet-routing-container {
  display: none;
}

.leaflet-bottom.leaflet-right {
  display: none;
}

.leaflet-marker-icon {
  background: none;
  border: none;

  > i {
    color: black;
    font-size: 20px;
  }
}

.overflow-auto {
  overflow: auto;
}

.overflow-hidden {
  overflow: hidden;
}

.flex-grow {
  flex-grow: 1;
}

.full-col {
  height: 100%;
  display: flex;
  flex-direction: column;
}


.full-height {
  height: 100%;
}

.flip-y {
  transform: scale(-1, 1);
}
